#contact__form {
	margin-left: 1rem;
}

.form__row {
	display: flex;
	margin-bottom: 2.5rem;
	width: 50%;
}

#contact__form .form__row .icon__wrapper svg {
	margin-right: 1.5rem;
	width: 3.5rem;
}

#contact__form .form__row .icon__wrapper svg path {
	fill: var(--brand-dark);
	width: 100%;
}

.text-input__wrapper {
	position: relative;
	width: calc(100% - 4.5rem);
}

#contact__form .text-input__wrapper label {
	color: var(--light-gray);
	font-size: 1.8rem;
	left: 0;
	position: absolute;
	top: -0.5rem;
	transition: all 300ms ease-in-out;
}

#contact__form .form__row input[type='text']:focus + label,
#contact__form .form__row input[type='text'].not-empty + label,
#contact__form .form__row input[type='email']:focus + label,
#contact__form .form__row input[type='email'].not-empty + label,
#contact__form .form__row input[type='tel']:focus + label,
#contact__form .form__row input[type='tel'].not-empty + label,
#contact__form .form__row input[type='password'].not-empty + label,
#contact__form .form__row input[type='password']:focus + label {
	color: var(--brand-dark);
	font-size: 1.4rem;
	top: -2rem;
}

#contact__form .form__row input[type='text'],
#contact__form .form__row input[type='email'],
#contact__form .form__row input[type='tel'],
#contact__form .form__row input[type='password'] {
	border: 0;
	border-bottom: 0.1rem solid var(--brand-dark);
	font-size: 1.8rem;
	width: 100%;
}

#contact__form fieldset {
	border: 0;
	padding: 0;
}

#contact__form legend {
	color: var(--brand-dark);
	font-size: 2rem;
	margin-bottom: 1.5rem;
}

#contact__form .radio__item {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-right: 3rem;
}

#contact__form .radio__item .icon__wrapper {
	cursor: pointer;
}

#contact__form .radio__item .icon__wrapper svg {
	margin: 0;
	width: 3rem;
}

#contact__form .form__row textarea {
	border: 0;
	border-bottom: 0.1rem solid var(--brand-dark);
	border-left: 0.1rem dashed var(--brand-dark);
	font-family: var(--primary-font);
	font-size: 1.6rem;
	min-height: 3.5rem;
	max-width: calc(100% - 4.5rem);
	min-width: calc(100% - 4.5rem);
}

#contact__form .form__row.form--bottom label,
#contact__form .form__row.form--bottom .label {
	font-size: 1.4rem;
	vertical-align: middle;
}

#contact__form .form__row input[type='checkbox'] {
	height: 1.8rem;
	width: 1.8rem;
	margin-right: 1.5rem;
	vertical-align: middle;
}

.form--bottom {
	align-items: center;
	justify-content: space-between;
}

#contact__form button,
#contact__form .button {
	align-items: center;
	background: var(--brand-dark);
	border: none;
	border-radius: 0;
	color: white;
	cursor: pointer;
	display: inline-flex;
	font-size: 1.6rem;
	font-weight: 400;
	height: 3rem;
	justify-content: center;
	margin-left: auto;
	min-width: 5rem;
	padding: 1rem 2rem;
	text-transform: uppercase;
	transition: all 300ms ease-in-out;
	vertical-align: middle;
}

#contact__form button:hover,
#contact__form .button:hover {
	background: var(--brand-light);
	color: var(--black);
}

.frame {
	margin: 5rem auto;
	display: block;
}

.no-focus:focus {
	outline: none;
}

#contact__form .form__row input[type='text'].no-focus + label,
#contact__form .form__row input[type='email'].no-focus + label,
#contact__form .form__row input[type='tel'].no-focus + label,
#contact__form .form__row input[type='password'].no-focus + label {
	color: var(--brand-dark);
	font-size: 1.4rem;
	top: -2rem;
}

.section__banner {
	background: var(--brand-dark);
	color: white;
	font-family: var(--headings-font);
	font-size: 2.4rem;
	line-height: 1.5;
	margin-bottom: 2.5rem;
	padding: 2.5rem 3rem;
}

p {
	font-size: 1.4rem;
	line-height: 1.5;
	margin-bottom: 2.5rem;
}

.section__images {
	padding-top: 0.5rem;
}

.section__images img {
	flex-basis: 50%;
}
.section__images img:not(:last-child) {
	margin-right: 3rem;
}

h2,
.heading_2 {
	background: var(--brand-dark);
	color: white;
	font-family: var(--headings-font);
	font-size: 2.8rem;
	font-weight: 400;
	margin: 3rem 0;
	padding: 1rem 3rem;
	text-transform: uppercase;
}

.projects__list {
	flex-wrap: wrap;
	justify-content: space-between;
}

.projects__list img {
	max-width: 100%;
}

.project__item {
	flex-basis: 30%;
	margin-bottom: 3rem;
}

.project__item h3 {
	font-size: 1.6rem;
	margin: 1rem 0;
	text-transform: uppercase;
}

.project__item a,
.project__item .link,
.project__item .another-link {
	color: var(--gray);
	cursor: pointer;
	font-size: 1.4rem;
	text-decoration: underline;
	text-transform: capitalize;
}

.project__item a:hover,
.project__item .link:hover {
	text-decoration: none;
}

.frames__wrapper {
	width: 95%;
	max-width: 1117px;
	margin: 0 auto;
}

.frames__wrapper h3 {
	background: var(--dark-gray);
	color: whitesmoke;
	font-size: 1.6rem;
	margin: 1rem 0;
	padding: 1.5rem;
}

.frames__wrapper > div {
	display: flex;
	justify-content: space-between;
}

.frames__wrapper > div > iframe {
	width: calc(50% - 15px);
	height: 80vh;
}

.frames__wrapper > div > iframe.nesting__frame {
	width: 100%;
	height: 80vh;
}

[role='banana'] {
	font-size: 2rem;
}

.btn {
	align-items: center;
	background: var(--brand-dark);
	border: none;
	border-radius: 0;
	color: white;
	cursor: pointer;
	display: inline-flex;
	font-size: 1.6rem;
	font-weight: 400;
	height: 3rem;
	justify-content: center;
	min-width: 5rem;
	padding: 1rem 2rem;
	text-transform: uppercase;
	transition: all 300ms ease-in-out;
	vertical-align: middle;
}

.btn:hover {
	background: var(--brand-light);
	color: var(--black);
}

.icon__links {
	display: flex;
	justify-content: space-around;
}

.icon__link svg {
	fill: black;
	width: 2.4rem;
}

@import url(https://fonts.googleapis.com/css2?family=Lato&family=Quicksand&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header,
.header {
	flex-direction: column;
}

header > *,
.header > * {
	margin-bottom: 3rem;
}

.header__top {
	justify-content: space-between;
}

.logo__link {
	display: block;
	height: 4rem;
	font-size: 3.5rem;
	color: gray;
	font-weight: 400;
	font-family: var(--headings-font);
	text-decoration: none;
}

.logo__link > svg {
	height: 100%;
	vertical-align: baseline;
	margin-right: 1rem;
}

.logo__link > svg .a {
	fill: var(--black);
}

.logo__link > svg .b {
	fill: var(--brand-dark);
}

.search__widget {
	font-size: 0;
}

.search__widget > input {
	border: 0.1rem solid var(--dark-gray);
	border-radius: 0;
	height: 3rem;
	line-height: 3rem;
	padding: 0 0.5rem;
	vertical-align: middle;
}

.search__widget > button,
.search__widget > .button {
	align-items: center;
	background: var(--brand-dark);
	border: none;
	border-radius: 0;
	color: white;
	cursor: pointer;
	display: inline-flex;
	font-size: 1.4rem;
	font-weight: 400;
	height: 3rem;
	justify-content: center;
	min-width: 5rem;
	text-transform: uppercase;
	transition: all 300ms ease-in-out;
	vertical-align: middle;
}

.search__widget > button:hover,
.search__widget > .button:hover {
	background: var(--brand-light);
	color: var(--black);
}

nav[aria-label='main'] > ul,
.header-nav > ul {
	display: flex;
	height: 4rem;
	list-style-type: none;
	margin: 0;
	padding: 0;
	width: 100%;
}

nav[aria-label='main'] > ul li,
.header-nav > ul li {
	flex-basis: 12.5%;
}

nav[aria-label='main'] > ul div,
.header-nav > ul div,
nav[aria-label='main'] > ul a,
.header-nav > ul a {
	align-items: center;
	background: var(--lighter-gray);
	color: var(--black);
	cursor: pointer;
	display: flex;
	font-size: 1.4rem;
	height: 100%;
	justify-content: center;
	text-decoration: none;
	text-transform: uppercase;
	transition: all 300ms ease-in-out;
}

nav[aria-label='main'] > ul a.current,
.header-nav > ul a.current {
	background: var(--brand-dark);
	color: white;
	pointer-events: none;
}

nav[aria-label='main'] > ul div:hover,
nav[aria-label='main'] > ul div:focus,
nav[aria-label='main'] > ul a:hover,
nav[aria-label='main'] > ul a:focus,
.header-nav > ul a:hover,
.header-nav > ul a:focus {
	background: var(--brand-dark);
	color: white;
}

h1,
.heading_1 {
	background: var(--lighter-gray);
	color: var(--brand-dark);
	font-family: var(--headings-font);
	font-size: 4rem;
	font-weight: 400;
	margin-top: 0;
	padding-left: 2.5rem;
	text-transform: uppercase;
}

h1 > span,
.heading_1 > span {
	background: white;
	padding: 0 1rem;
}

.login__btn {
	align-items: center;
	margin: 0 1.5rem 0 auto;
	background: var(--brand-dark);
	border: none;
	border-radius: 0;
	color: white;
	cursor: pointer;
	display: flex;
	font-size: 1.4rem;
	font-weight: 400;
	height: 3rem;
	justify-content: center;
	min-width: 5rem;
	padding: 0 1rem;
	text-decoration: none;
	text-transform: uppercase;
	transition: all 300ms ease-in-out;
	vertical-align: middle;
}

.login__btn:hover {
	background: var(--brand-light);
	color: var(--black);
}

.user {
	display: flex;
	margin: 0 1.5rem 0 auto;
}

.user a {
	font-size: 1.4rem;
}

.user a span {
	display: inline-block;
	height: 3rem;
	width: 3rem;
	vertical-align: middle;
}

aside .aside__content,
.aside .aside__content {
	position: -webkit-sticky;
	position: sticky;
	top: 3rem;
}

aside nav ul,
.aside .nav ul {
	border: 0.2rem solid var(--brand-dark);
	margin: 0;
	padding: 0;
	list-style-type: none;
	width: 100%;
}

aside nav ul li,
.aside .nav ul li {
	background: var(--lightest-gray);
	font-size: 1.6rem;
}

aside nav ul li:not(:last-child),
.aside .nav ul li:not(:last-child) {
	border-bottom: 0.1rem solid var(--brand-dark);
}

aside nav ul li a,
.aside .nav ul li a,
aside nav ul li button,
.aside .nav ul li button,
aside nav ul li div,
.aside .nav ul li div {
	color: var(--dark-gray);
	cursor: pointer;
	display: block;
	padding: 1rem 1.5rem;
	text-transform: uppercase;
	transition: all 300ms ease-in-out;
}

aside nav ul li a:hover,
aside nav ul li a:focus,
.aside .nav ul li a:hover,
.aside .nav ul li a:focus,
aside nav ul li button:hover,
aside nav ul li button:focus,
.aside .nav ul li button:hover,
.aside .nav ul li button:focus,
aside nav ul li div:hover,
aside .nav ul li div:hover {
	background: var(--brand-dark);
	color: white;
}

.aside__section {
	margin-bottom: 3rem;
}

.aside__section img {
	max-width: 100%;
}

.aside-heading {
	background: var(--brand-dark);
	color: white;
	font-family: var(--headings-font);
	font-size: 1.8rem;
	font-weight: 400;
	margin: 0 0 1rem 0;
	padding: 1rem 1.5rem;
	text-transform: uppercase;
}

.whatsnew {
	color: white;
	display: block;
	font-size: 1.6rem;
	text-align: center;
	width: 100%;
}

.screener {
	position: fixed;
	height: 100vh;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.8);
	top: 0;
	left: 0;
}

[role='dialog'] {
	width: 60%;
	max-width: 50rem;
	background: white;
	padding: 3rem;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -75%);
}

[role='dialog'] .form__row {
	width: 100%;
}

[role='dialog'] #contact__form button {
	margin: auto;
}

[aria-label='Close'] {
	background: var(--brand-dark);
	border: none;
	border-radius: 50%;
	color: white;
	cursor: pointer;
	font-size: 1.4rem;
	font-weight: 400;
	height: 3rem;
	min-width: 3rem;
	position: absolute;
	right: 1.5rem;
	top: 1.5rem;
	text-transform: uppercase;
	transition: all 300ms ease-in-out;
	vertical-align: middle;
}

[aria-label='Close']:hover {
	background: var(--brand-light);
	color: var(--black);
}

#contact__form {
	margin-left: 1rem;
}

.form__row {
	display: flex;
	margin-bottom: 2.5rem;
	width: 50%;
}

#contact__form .form__row .icon__wrapper svg {
	margin-right: 1.5rem;
	width: 3.5rem;
}

#contact__form .form__row .icon__wrapper svg path {
	fill: var(--brand-dark);
	width: 100%;
}

.text-input__wrapper {
	position: relative;
	width: calc(100% - 4.5rem);
}

#contact__form .text-input__wrapper label {
	color: var(--light-gray);
	font-size: 1.8rem;
	left: 0;
	position: absolute;
	top: -0.5rem;
	transition: all 300ms ease-in-out;
}

#contact__form .form__row input[type='text']:focus + label,
#contact__form .form__row input[type='text'].not-empty + label,
#contact__form .form__row input[type='email']:focus + label,
#contact__form .form__row input[type='email'].not-empty + label,
#contact__form .form__row input[type='tel']:focus + label,
#contact__form .form__row input[type='tel'].not-empty + label,
#contact__form .form__row input[type='password'].not-empty + label,
#contact__form .form__row input[type='password']:focus + label {
	color: var(--brand-dark);
	font-size: 1.4rem;
	top: -2rem;
}

#contact__form .form__row input[type='text'],
#contact__form .form__row input[type='email'],
#contact__form .form__row input[type='tel'],
#contact__form .form__row input[type='password'] {
	border: 0;
	border-bottom: 0.1rem solid var(--brand-dark);
	font-size: 1.8rem;
	width: 100%;
}

#contact__form fieldset {
	border: 0;
	padding: 0;
}

#contact__form legend {
	color: var(--brand-dark);
	font-size: 2rem;
	margin-bottom: 1.5rem;
}

#contact__form .radio__item {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-right: 3rem;
}

#contact__form .radio__item .icon__wrapper {
	cursor: pointer;
}

#contact__form .radio__item .icon__wrapper svg {
	margin: 0;
	width: 3rem;
}

#contact__form .form__row textarea {
	border: 0;
	border-bottom: 0.1rem solid var(--brand-dark);
	border-left: 0.1rem dashed var(--brand-dark);
	font-family: var(--primary-font);
	font-size: 1.6rem;
	min-height: 3.5rem;
	max-width: calc(100% - 4.5rem);
	min-width: calc(100% - 4.5rem);
}

#contact__form .form__row.form--bottom label,
#contact__form .form__row.form--bottom .label {
	font-size: 1.4rem;
	vertical-align: middle;
}

#contact__form .form__row input[type='checkbox'] {
	height: 1.8rem;
	width: 1.8rem;
	margin-right: 1.5rem;
	vertical-align: middle;
}

.form--bottom {
	align-items: center;
	justify-content: space-between;
}

#contact__form button,
#contact__form .button {
	align-items: center;
	background: var(--brand-dark);
	border: none;
	border-radius: 0;
	color: white;
	cursor: pointer;
	display: inline-flex;
	font-size: 1.6rem;
	font-weight: 400;
	height: 3rem;
	justify-content: center;
	margin-left: auto;
	min-width: 5rem;
	padding: 1rem 2rem;
	text-transform: uppercase;
	transition: all 300ms ease-in-out;
	vertical-align: middle;
}

#contact__form button:hover,
#contact__form .button:hover {
	background: var(--brand-light);
	color: var(--black);
}

.frame {
	margin: 5rem auto;
	display: block;
}

.no-focus:focus {
	outline: none;
}

#contact__form .form__row input[type='text'].no-focus + label,
#contact__form .form__row input[type='email'].no-focus + label,
#contact__form .form__row input[type='tel'].no-focus + label,
#contact__form .form__row input[type='password'].no-focus + label {
	color: var(--brand-dark);
	font-size: 1.4rem;
	top: -2rem;
}

footer,
.footer {
	align-items: flex-end;
	background: var(--brand-dark);
	display: flex;
	padding: 2.5rem 3rem;
}

footer ul,
.footer ul {
	color: white;
	list-style-type: none;
	margin: 0 3rem 0 0;
	max-width: 30rem;
	padding: 0;
}

footer section ul,
.footer section ul {
	align-items: center;
	display: flex;
}

footer nav ul li:not(:last-child),
.footer nav ul li:not(:last-child) {
	margin-bottom: 1rem;
	position: relative;
}

footer section ul li,
.footer section ul li {
	padding-right: 1rem;
	position: relative;
}

footer ul li a,
.footer ul li a {
	color: white;
	font-size: 1.4rem;
}

footer a svg,
.footer a svg {
	width: 2.4rem;
}

footer a svg path,
.footer a svg path {
	fill: white;
}

footer .copy__container,
.footer .copy__container {
	color: white;
	font-size: 1.2rem;
	justify-self: flex-end;
	margin-left: auto;
}

:root {
	--bg-color: #1b1d21;
	--black: #131516;
	--dark-gray: #666;
	--gray: #999;
	--light-gray: #bec6d0;
	--borders-gray: #cecece;
	--lighter-gray: #ececec;
	--lightest-gray: #f4f7fa;
	--brand-dark: #00615a;
	--brand-light: #26bfb5;
	--blue: #0c72bb;

	--primary-font: 'Lato', 'sans-serif';
	--headings-font: 'Quicksand', 'sans-serif';
}
html,
body {
	font-family: 'Lato', 'sans-serif';
	font-family: var(--primary-font);
	font-size: 62.5%;
}

html {
	box-sizing: border-box;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

body {
	margin: 0;
}

#top__wrapper {
	margin: 0 auto;
	max-width: 99rem;
	padding: 3rem 1.5rem 0;
}

.row {
	display: flex;
}

aside,
.aside {
	flex-basis: 26%;
	margin-right: 3rem;
}

main,
.main {
	flex-basis: 71%;
}

.page__content {
	min-height: calc(100vh - 34rem);
}

.skip-links__wrapper {
	position: relative;
}

.skip__link {
	background: white;
	border: 0.1rem solid #00615a;
	border: 0.1rem solid var(--brand-dark);
	box-shadow: 0.1rem 0.3rem 0.3rem 0.1rem rgba(51, 51, 51, 0.6);
	color: #00615a;
	color: var(--brand-dark);
	display: block;
	font-size: 1.6rem;
	padding: 1.5rem 1rem;
	position: absolute;
	top: -6rem;
	transition: all 300ms ease-in-out;
	left: 1.5rem;
}

.skip__link:focus {
	top: 0;
}

.section__banner {
	background: var(--brand-dark);
	color: white;
	font-family: var(--headings-font);
	font-size: 2.4rem;
	line-height: 1.5;
	margin-bottom: 2.5rem;
	padding: 2.5rem 3rem;
}

p {
	font-size: 1.4rem;
	line-height: 1.5;
	margin-bottom: 2.5rem;
}

.section__images {
	padding-top: 0.5rem;
}

.section__images img {
	flex-basis: 50%;
}
.section__images img:not(:last-child) {
	margin-right: 3rem;
}

h2,
.heading_2 {
	background: var(--brand-dark);
	color: white;
	font-family: var(--headings-font);
	font-size: 2.8rem;
	font-weight: 400;
	margin: 3rem 0;
	padding: 1rem 3rem;
	text-transform: uppercase;
}

.projects__list {
	flex-wrap: wrap;
	justify-content: space-between;
}

.projects__list img {
	max-width: 100%;
}

.project__item {
	flex-basis: 30%;
	margin-bottom: 3rem;
}

.project__item h3 {
	font-size: 1.6rem;
	margin: 1rem 0;
	text-transform: uppercase;
}

.project__item a,
.project__item .link,
.project__item .another-link {
	color: var(--gray);
	cursor: pointer;
	font-size: 1.4rem;
	text-decoration: underline;
	text-transform: capitalize;
}

.project__item a:hover,
.project__item .link:hover {
	text-decoration: none;
}

.news__list {
	flex-direction: column;
}

.news__item {
	margin-bottom: 3rem;
}

.news__item h2 {
	font-size: 1.6rem;
	margin: 0 0 1rem;
	padding: 1rem;
}

.news__item .content {
	display: flex;
}

.news__item .content > div {
	display: flex;
	flex-direction: column;
}

.news__item p {
	font-size: 1.6rem;
	margin: 0;
}

.news__item p:after {
	content: '';
	display: table;
	clear: both;
}

.news__item .content img {
	float: left;
	margin: 0 1rem 0 0;
}

.news__item .content a {
	align-self: flex-end;
	font-size: 1.6rem;
	margin-top: auto;
}

.news__item .content .btn {
	align-self: flex-end;
	background: var(--brand-dark);
	border: 0.1rem solid var(--brand-dark);
	color: white;
	cursor: pointer;
	font-size: 1.6rem;
	padding: 1rem 1.5rem;
	margin-top: auto;
}

.news__item .content .btn:hover {
	background: white;
	color: var(--brand-dark);
}

.btns__list {
	display: flex;
	margin-bottom: 3rem;
}

.fake__btn {
	background: var(--brand-dark);
	border: none;
	border-right: 0.1rem solid white;
	border-radius: 0;
	color: white;
	cursor: pointer;
	font-size: 1.6rem;
	font-weight: 400;
	min-width: 5rem;
	padding: 1rem 2rem;
	text-transform: uppercase;
	transition: all 300ms ease-in-out;
	vertical-align: middle;
}

.fake__btn:hover {
	background: var(--brand-light);
	color: var(--black);
}

.frames__wrapper {
	width: 95%;
	max-width: 1117px;
	margin: 0 auto;
}

.frames__wrapper h3 {
	background: var(--dark-gray);
	color: whitesmoke;
	font-size: 1.6rem;
	margin: 1rem 0;
	padding: 1.5rem;
}

.frames__wrapper > div {
	display: flex;
	justify-content: space-between;
}

.frames__wrapper > div > iframe {
	width: calc(50% - 15px);
	height: 80vh;
}

.frames__wrapper > div > iframe.nesting__frame {
	width: 100%;
	height: 80vh;
}

[role='banana'] {
	font-size: 2rem;
}

.btn {
	align-items: center;
	background: var(--brand-dark);
	border: none;
	border-radius: 0;
	color: white;
	cursor: pointer;
	display: inline-flex;
	font-size: 1.6rem;
	font-weight: 400;
	height: 3rem;
	justify-content: center;
	min-width: 5rem;
	padding: 1rem 2rem;
	text-transform: uppercase;
	transition: all 300ms ease-in-out;
	vertical-align: middle;
}

.btn:hover {
	background: var(--brand-light);
	color: var(--black);
}

.icon__links {
	display: flex;
	justify-content: space-around;
}

.icon__link svg {
	fill: black;
	width: 2.4rem;
}

.dd__wrapper {
	width: 45%;
	height: 4rem;
	position: relative;
}

.dd__btn {
	background: #f7f8fc;
	background: linear-gradient(to right, #f7f8fc 80%, #00615a 80%, #00615a 100%);
	border: 0.1rem solid var(--dark-gray);
	height: 100%;
	padding-right: 20%;
	width: 100%;
	cursor: pointer;
}

.dd__btn::after {
	content: '▾';
	position: absolute;
	right: calc(10% - 0.6rem);
	color: white;
	font-size: 2rem;
	top: 0.7rem;
}

.dd__list {
	list-style-type: none;
	border: 0.1rem solid transparent;
	margin: 0;
	padding: 0;
	position: absolute;
	top: 100%;
	left: 0;
	font-size: 1.4rem;
	width: 100%;
	max-height: 0;
	overflow: hidden;
	transition: all 500ms ease-in-out;
}

.dd__list.expanded {
	border-color: var(--dark-gray);
	max-height: 100vh;
}

.dd__list li {
	background-color: #f7f8fc;
	padding: 0.5rem;
	transition: all 300ms ease-in-out;
}

.dd__list li:hover {
	background-color: var(--brand-dark);
	color: white;
}

.dd__list.expanded li {
	cursor: pointer;
}

.positions__wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.position__box {
	width: 45%;
	text-align: center;
	margin: 1.5rem;
	display: flex;
	flex-direction: column;
	background: var(--lightest-gray);
	padding: 1.5rem;
	box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.33);
}

.position__box h3 {
	font-size: 2rem;
}

.position__box p {
	font-size: 1.6rem;
	text-align: left;
	margin: 1.5rem;
}

.position__box a {
	background-color: var(--brand-dark);
	color: white;
	padding: 1rem 2rem;
	font-size: 1.6rem;
	text-decoration: none;
	border-radius: 0.4rem;
	justify-self: flex-end;
	margin: auto 2rem 0;
}

.application .formInner {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.application caption {
	color: var(--brand-dark);
	font-family: var(--headings-font);
	font-size: 2.4rem;
	font-weight: 400;
	margin-bottom: 1.5rem;
}

.application .formInner .field__wrapper {
	display: flex;
	font-size: 1.6rem;
	justify-content: space-between;
	margin-bottom: 1.5rem;
	width: 45%;
}

.application .formInner .field__wrapper input {
	font-size: 1.6rem;
	width: 20rem;
	height: 3rem;
}

.application .formInner .field__wrapper input:focus {
	outline: none;
}

.application button {
	background-color: var(--brand-dark);
	color: white;
	padding: 1rem 2rem;
	font-size: 1.6rem;
	border: none;
	border-radius: 0.4rem;
	margin: 0 auto;
}


.btns__list {
	display: flex;
	margin-bottom: 3rem;
}

.fake__btn {
	background: var(--brand-dark);
	border: none;
	border-right: 0.1rem solid white;
	border-radius: 0;
	color: white;
	cursor: pointer;
	font-size: 1.6rem;
	font-weight: 400;
	min-width: 5rem;
	padding: 1rem 2rem;
	text-transform: uppercase;
	transition: all 300ms ease-in-out;
	vertical-align: middle;
}

.fake__btn:hover {
	background: var(--brand-light);
	color: var(--black);
}

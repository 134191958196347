.dd__wrapper {
	width: 45%;
	height: 4rem;
	position: relative;
}

.dd__btn {
	background: #f7f8fc;
	background: -moz-linear-gradient(left, #f7f8fc 80%, #00615a 80%, #00615a 100%);
	background: -webkit-linear-gradient(left, #f7f8fc 80%, #00615a 80%, #00615a 100%);
	background: linear-gradient(to right, #f7f8fc 80%, #00615a 80%, #00615a 100%);
	border: 0.1rem solid var(--dark-gray);
	height: 100%;
	padding-right: 20%;
	width: 100%;
	cursor: pointer;
}

.dd__btn::after {
	content: '▾';
	position: absolute;
	right: calc(10% - 0.6rem);
	color: white;
	font-size: 2rem;
	top: 0.7rem;
}

.dd__list {
	list-style-type: none;
	border: 0.1rem solid transparent;
	margin: 0;
	padding: 0;
	position: absolute;
	top: 100%;
	left: 0;
	font-size: 1.4rem;
	width: 100%;
	max-height: 0;
	overflow: hidden;
	transition: all 500ms ease-in-out;
}

.dd__list.expanded {
	border-color: var(--dark-gray);
	max-height: 100vh;
}

.dd__list li {
	background-color: #f7f8fc;
	padding: 0.5rem;
	transition: all 300ms ease-in-out;
}

.dd__list li:hover {
	background-color: var(--brand-dark);
	color: white;
}

.dd__list.expanded li {
	cursor: pointer;
}

.positions__wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.position__box {
	width: 45%;
	text-align: center;
	margin: 1.5rem;
	display: flex;
	flex-direction: column;
	background: var(--lightest-gray);
	padding: 1.5rem;
	-webkit-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.33);
	box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.33);
}

.position__box h3 {
	font-size: 2rem;
}

.position__box p {
	font-size: 1.6rem;
	text-align: left;
	margin: 1.5rem;
}

.position__box a {
	background-color: var(--brand-dark);
	color: white;
	padding: 1rem 2rem;
	font-size: 1.6rem;
	text-decoration: none;
	border-radius: 0.4rem;
	justify-self: flex-end;
	margin: auto 2rem 0;
}

.application .formInner {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.application caption {
	color: var(--brand-dark);
	font-family: var(--headings-font);
	font-size: 2.4rem;
	font-weight: 400;
	margin-bottom: 1.5rem;
}

.application .formInner .field__wrapper {
	display: flex;
	font-size: 1.6rem;
	justify-content: space-between;
	margin-bottom: 1.5rem;
	width: 45%;
}

.application .formInner .field__wrapper input {
	font-size: 1.6rem;
	width: 20rem;
	height: 3rem;
}

.application .formInner .field__wrapper input:focus {
	outline: none;
}

.application button {
	background-color: var(--brand-dark);
	color: white;
	padding: 1rem 2rem;
	font-size: 1.6rem;
	border: none;
	border-radius: 0.4rem;
	margin: 0 auto;
}
